import type { Page, ProductPage } from "@/types";

export const useCart = () => {
  const cartItems = useState<string[]>("cartItems", () => []);
  const isCartOpen = useState<boolean>("isCartOpen", () => false);
  const isLoading = useState<boolean>("isCartFetching", () => false);
  const products = useState<Page<ProductPage>[]>("cartProducts", () => []);

  const initCart = () => {
    cartItems.value = JSON.parse(localStorage.getItem("cartItems") || "[]");
  };

  const closeCart = () => {
    isCartOpen.value = false;
  };

  const openCart = () => {
    isCartOpen.value = true;
  };

  const addItem = (id: string) => {
    cartItems.value = [id, ...cartItems.value];
    localStorage.setItem("cartItems", JSON.stringify(cartItems.value));
    isCartOpen.value = true;
    fetchProducts();
  };

  const removeItem = (id: string) => {
    const existingItems = [...cartItems.value];
    cartItems.value = existingItems.filter((item) => item !== id);
    localStorage.setItem("cartItems", JSON.stringify(cartItems.value));

    products.value = [...products.value].filter(
      (product) => product.uuid !== id,
    );
  };

  const fetchProducts = async () => {
    if (cartItems.value.length === 0) {
      products.value = [];
      return;
    }

    isLoading.value = true;
    const { data } = await useStories({
      by_uuids_ordered: cartItems.value.join(","),
    });
    products.value = data.stories as Page<ProductPage>[];
    isLoading.value = false;
  };

  return {
    initCart,
    addItem,
    removeItem,
    cartItems,
    products,
    isLoading,
    isCartOpen,
    openCart,
    closeCart,
    fetchProducts,
  };
};
